import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ContentContainer from '@solid-ui-components/ContentContainer'
import StackTitle from '@solid-ui-components/StackTitle'
import ListItem from '@solid-ui-components/ListItem'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const FeaturesBlock06 = ({
  content: { kicker, title, text_1, text_2, collection, buttons }
}) => (
  <Container sx={{ textAlign: `center` }}>
    <StackTitle kicker={kicker} title={title} lines={[text_1, text_2]} />
    {collection && (
      <Box effect='fadeInDown' duration={0}>
        <Flex sx={{ justifyContent: `center`, flexWrap: `wrap`, m: -3 }}>
          {collection.map(({ container, ...props }, index) => (
            <Box
              key={`item-${index}`}
              sx={{ flexBasis: [`1`, `1/2`, null, `1/4`], p: 3 }}
            >
              <ContentContainer content={container}>
                <ListItem
                  {...props}
                  iconProps={{ round: true }}
                  vertical
                  center
                />
              </ContentContainer>
            </Box>
          ))}
        </Flex>
      </Box>
    )}
    <ContentButtons content={buttons} />
  </Container>
)

export default WithDefaultContent(FeaturesBlock06)
