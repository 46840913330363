import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Box, Flex } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import ModalRegister from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Features from '@solid-ui-blocks/Features/Block06'
import Usecases from '@solid-ui-blocks/Features/Block05'
// import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
// import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block06'
// import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Pricing from '@solid-ui-blocks/Pricing/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block04'
import Strategies from '@solid-ui-blocks/Stats/Block01'
// import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
// import Companies from '@solid-ui-blocks/Companies/Block01'
// import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '@flexiblocks/templates/saas/_theme'
import styles from '@flexiblocks/templates/saas/_index.styles'
import HowItWorks from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import Faq from '@solid-ui-blocks/Faq/Block01'
// import Stats from '@solid-ui-blocks/Stats/Block01'
import axios from "axios"
import "./_google_translate.css"

const IndexPage = props => {
  const { allBlockContent } = useStaticQuery(query)
  const [client, setClient] = useState({});
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  useEffect(() => {
    axios
      .get(
        "https://api.country.is"
      )
      .then(function(response) {
        // console.log("index client: ", response);
        if (response) setClient(response.data);
      });
  }, []);

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "FieldNext",
    "alternateName": "Employee Tracking Software",
    "description": "FieldNext is employee monitoring software for benefits of both employer and field employee for easy field operation and organisation productivity improvement. It servers employee location tracking, monitoring, reporting with attendances management, expenses reimbursement management, field works management and reporting.",
    "url": "https://www.fieldnext.io",
    "logo": "https://fieldnext-media.s3.ap-south-1.amazonaws.com/logo-fieldnext.png",
    "sameAs": [
      "https://youtu.be/qjqHoKtzj_w",
      "https://www.fieldnext.io"
    ]
  }
 
  return (
    <Layout theme={theme} {...props}>
      <Seo 
        title="Field Workforce Management | Employee Tracking | Survey App" 
        name="FieldNext"
        description="FieldNext offers the latest mobile-enabled software for field employee tracking, monitoring, reporting, attendance, expense, visit, and task management."
        keywords={["survey app, survey mobile app, best survey app, land survey app, real research survey app, bhoomi survey app, gps survey app, field employee tracking software, field employee tracking app, employee tracking, employee tracking systems, employee tracking system project, employee tracking software, employee tracking app, employee tracking app india, employee tracker, employee tracker software, dynamics 365 field service, best employee tracking app, sales employee tracking app, sales employee tracking app india, gps employee tracking, field service software, field service management, field service management software, field service app"]}
        siteUrl="https://fieldnext.io"
        author="fieldnext"
        schemaMarkup={schema}
      />
      {/* Modals */}
      {/* <ModalWithTabs content={content['authentication']} reverse /> */}
      <ModalRegister content={content['register']} />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Flex 
        Centered
        variant='full'
        css={{
          // background: "#f00", 
          justifyContent: "right",
          // marginBottom: -24
        }} 
      >
        <div Centered id="google_translate_element" />
      </Flex>
      <Header content={content['header']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
      </Container>
      <Divider space='5' />
      <Features content={content['features']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.howContainer}>
        <HowItWorks content={content['how-it-works']} />
      </Container>
      <Divider space='5' />
      <Usecases content={content['usecases']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.howContainer}>
        <WhyChooseUs content={content['why-choose-us']} />
      </Container>
      <Divider space='5' />
      <Pricing content={{...content['pricing'], client}}/>
      <Divider space='5' />
      <Container variant='narrow'>
        <Faq content={content['faq']} />
      </Container>
      <Divider space='5' />
      <Strategies content={content['get-started']} />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

const query = graphql`
  query fieldnextIndexBlockContent {
    allBlockContent(filter: { page: { in: ["fieldnext/index", "fieldnext/shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
