import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import ListItem1 from '@solid-ui-components/ListItem1'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import FlexImage from '@solid-ui-components/FlexImage'
import ContentImages from '@solid-ui-components/ContentImages'
import getImage from '@solid-ui-components/utils/getImage'

const styles = {
  listItem: {
    flex: `1 1 0`,
    minWidth: 300,
    p: 3
  },
  itemDescription: {
    flexBasis: `3/5`,
    flexGrow: 1,
    // order: [1, null, 0],
    mt:[0, 0],
    mb: 3,
    fontSize: [16, 15],
    color: '#444'
  },
  title: {
    marginTop: 56,
    fontSize: 20,
    color: 'black'
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`,
    margin: -32,
  },
  image: {
    display: [`block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    // minHeight: `15rem`,
    div: {
      p: `0 !important`
    }
  },
}

const FeaturesBlock05 = ({
  content: { kicker, title, text_1, text_2, collection }
}) => (
  <Container >
    <StackTitle kicker={kicker} title={title} lines={[text_1, text_2]} />
    <Flex sx={{ flexWrap: `wrap` }} >
      {collection?.map(
        (
          { title, description, icon, collection, buttons, container, images },
          index
        ) => (
          <Box
            key={`item-${index}`}
            effect='fadeInGrow'
            duration={0.55}
            delay={0.15 * (index + 1)}
            css={css(styles.listItem)}
          >
            <ContentContainer
              content={container}
              variant='cards.paper'
              sx={{ height: `full` }}
            >
              {/* Image */}
              <Box sx={{ flex: [0, 1], m: 2, mb: [null, null, null, 0] }}>
                <Box sx={styles.imageWrapper}>
                  <Img
                    image={getImage(images?.[0]?.src)}
                    alt={images?.[0]?.alt}
                    css={css(styles.image)}
                  />
                </Box>
              </Box>
              <ContentText content={title} variant='h4' sx={styles.title}/>
              <Flex sx={{ alignItems: `center`, flexWrap: `wrap` }}>
                <ContentText
                  content={description}
                  sx={styles.itemDescription}
                  mt={[3, null, 0]}
                />
                {collection && (
                  <Box sx={{ flexGrow: 1, mr: [3, null, 0] }}>
                    {collection.map((props, index) => (
                      <ListItem1 key={`item-${index}`} {...props} compact />
                    ))}
                  </Box>
                )}
              </Flex>
              <ContentButtons content={buttons} space={3} />
            </ContentContainer>
          </Box>
        )
      )}
    </Flex>
  </Container>
)

export default WithDefaultContent(FeaturesBlock05)
