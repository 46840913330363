import React, { useContext } from 'react'
import { Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentMap from '@solid-ui-components/ContentMap'
import Tabs from '@solid-ui-components/Tabs'
import Modal from '@solid-ui-components/Modal'
import { RegisterForm } from '@solid-ui-components/ContentForm'
import ListItem from '@solid-ui-components/ListItem'
import { TabsContext } from '@solid-ui-components/Tabs'
import { ModalContext } from '@solid-ui-components/Modal'

const ModalBlock01 = ({ content, reverse }) => {
  const { activeModal } = useContext(ModalContext)
  const {
    activeTab: { index = 0 }
  } = useContext(TabsContext)

  const { identifier, kicker, title, text_1, text_2 } = content

  if (activeModal && activeModal !== identifier) return null

  const images = content?.collection[index]?.images || content.images
  const map = content?.collection[index]?.map || content.map
  const hasMedia = images || map

  return (
    <Modal
      id={identifier}
      contentStyles={{
        maxWidth: hasMedia ? `narrow` : 900,
        p: 0
      }}
    >
      <Flex
        sx={{
          alignItems: `stretch`,
          flexDirection: [
            reverse ? `column-reverse` : `column`,
            reverse ? `row-reverse` : `row`
          ]
        }}
      >
        {/*hasMedia && (
          <Box
            sx={{
              display: [`none`, null, `block`],
              flex: 1,
              minWidth: [null, null, null, 500],
              height: 750,
              position: `relative`,
              borderRadius: reverse ? `right` : `left`,
              overflow: `hidden`
            }}
          >
            {images && (
              <ContentImages
                content={{ images }}
                imageFit='cover'
                height='100%'
                reverse={reverse}
              />
            )}
            {map && (
              <Reveal
                effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
                style={{ width: `100%`, height: `100%` }}
              >
                <ContentMap content={map} />
              </Reveal>
            )}
          </Box>
            )*/}
        {Array.isArray(content.collection) && (
          <Box sx={{ flex: 1, py: [4, 4], px: [3, 4] }}>
            <StackTitle
              kicker={kicker}
              title={title}
              lines={[text_1, text_2]}
              space={3}
            />
              {content.collection
                ?.filter(Boolean)
                ?.map(
                  (
                    { kicker, title, text_1, text_2, collection, form },
                    index
                  ) => (
                    <Reveal
                      key={`item-${index}`}
                      effect='fadeIn'
                      duration={0.5}
                      content={{ kicker }}
                    >
                      <StackTitle
                        title={title}
                        lines={[text_1, text_2]}
                        space={3}
                      />
                      {form && (
                        <RegisterForm
                          form={form}
                          id={`form.${identifier}.${
                            form.multiStep ? 'multi' : index
                          }`}
                        />
                      )}
                    </Reveal>
                  )
                )}
          </Box>
        )}
      </Flex>
    </Modal>
  )
}

export default ModalBlock01
