import React from 'react'
import { Flex, Box, Heading } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Icon from '@solid-ui-components/ContentIcon'

const ListItem = ({
  icon,
  iconProps,
  title,
  description,
  compact,
  vertical,
  center,
  middle //Centering for horizontal layout
}) => (
  <Flex
    sx={{
      flexDirection: vertical ? 'column' : 'row',
      alignItems: center ? `center` : `flex-start`,
      textAlign: center && vertical ? `center` : `unset`,
      justifyContent: middle ? `center` : `unset`,
      '& + &': { mt: description ? (compact ? 3 : 4) : 2 }
    }}
  >
    {icon?.src && (
      <Box
        sx={{
          display: `inline-flex`,
          alignItems: `center`,
          flexShrink: 0,
          [vertical ? 'mb' : 'mr']: compact ? 2 : 3
        }}
      >
        <Icon
          content={icon}
          size='sm'
          p={compact ? 1 : undefined}
          {...iconProps}
        />
      </Box>
    )}
    <Box sx={{ alignSelf: vertical ? `auto` : `center` }}>
      <ContentText
        content={title}
        as={Heading}
        variant={compact ? 'h6' : 'h5'}
        sx={{ 
          lineHeight: 'body',
          fontWeight: 800,
          color: '#666',
          fontSize: 15
          // '@media screen and (max-width: 768px)': {
          //   fontSize: 16,
          // },
        }}
        mb='0'
      />
      <ContentText
        content={description}
        variant={compact ? 'small' : 'p'}
        sx={{ 
          maxWidth: 330,
          fontSize: 15,
          '@media screen and (max-width: 768px)': {
            fontSize: 17,
          },
        }}
        mt={compact ? 2 : 3}
        mb='0'
      />
    </Box>
  </Flex>
)

ListItem.defaultProps = {
  iconProps: {}
}

export default ListItem
